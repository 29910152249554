import React from 'react';
import './App.css';
import SpaceBackground from './SpaceBackground';
import GlitchText from './GlitchText';
import majuImage from './img/maju-sticker.png';
import sylvanianImage from './img/sylvanian.png';

function Home() {
  return (
    <div>
      <SpaceBackground />
      <div className="container">
        <GlitchText text={"unknown watchers"} color={"#c154c1"} fontSize={"5vh"} />
        <div className="glitch">
          <img src={sylvanianImage} style={{ width: '50%', height: 'auto' }} alt="" />
          <GlitchText text={"We'll meet on 2025-03-30"} color={"#f88379"} fontSize={"3.5vh"} />
          <GlitchText text={"Be prepared"} color={"#ff6ec7"} fontSize={"3.5vh"} />
        </div>
        <div className="glitch">
          <GlitchText text={"this is important to <lifeform>"} />
          <img src={majuImage} style={{ width: '20%', height: 'auto' }} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Home;

