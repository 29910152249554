
import React from 'react';
import './App.css';
import SpaceBackground from './SpaceBackground';
import GlitchText from './GlitchText';
import talkingOnline from './img/talking_online.webp';

function Page9() {
    let fontSize = "2vh";
    return (
        <div>
            <SpaceBackground />
            <div className="glitch">
                <GlitchText text={"Eventualmente, começou uma pandemia mundial,"} fontSize={fontSize} color="#ff6ec7" />
                <GlitchText text={"e vocês passaram um tempo namorando a distância."} fontSize={fontSize} color="#ff6ec7" />

                <br />
                <img src={talkingOnline} style={{ width: 'auto', height: '30vh' }} alt="" />
                <br />
                <br />
                <br />
                <GlitchText text={"<watchers> <question>"} color="#f88379" fontSize={fontSize} />
                <GlitchText text={"what did you used to do together online?"} color="#ff00ff" fontSize={fontSize} />

            </div>
        </div>

    );
}

export default Page9;

