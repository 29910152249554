import React from 'react';
import './App.css';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import Page6 from './Page6';
import Page7 from './Page7';
import Page8 from './Page8';
import Page9 from './Page9';
import Page10 from './Page10';
import Page11 from './Page11';
import Page12 from './Page12';



import Home from './Home';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/memory1" element={<Page1 />} />
          <Route path="/memory2" element={<Page2 />} />
          <Route path="/memory3" element={<Page3 />} />
          <Route path="/memory4" element={<Page4 />} />
          <Route path="/memory5" element={<Page5 />} />
          <Route path="/memory6" element={<Page6 />} />
          <Route path="/memory7" element={<Page7 />} />
          <Route path="/memory8" element={<Page8 />} />
          <Route path="/memory9" element={<Page9 />} />
          <Route path="/memory10" element={<Page10 />} />
          <Route path="/memory11" element={<Page11 />} />
          <Route path="/memory12" element={<Page12 />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;

