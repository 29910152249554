
import React from 'react';
import './App.css';
import SpaceBackground from './SpaceBackground';
import GlitchText from './GlitchText';
import goingStreet from './img/going_street.webp';

function Page12() {
    let fontSize = "2vh";
    return (
        <div>
            <SpaceBackground />
            <div className="glitch">
                <GlitchText text={"Agora reunidos, vocês sempre estavam juntos."} fontSize={fontSize} color="#ff6ec7" />
                <GlitchText text={"Tanto na faculdade, quanto saindo"} fontSize={fontSize} color="#ff6ec7" />
                <GlitchText text={"para passear, quanto em casa."} fontSize={fontSize} color="#ff6ec7" />


                <br />
                <img src={goingStreet} style={{ width: 'auto', height: '30vh' }} alt="" />
                <br />
                <br />
                <br />
                <GlitchText text={"<watchers> <question>"} color="#f88379" fontSize={fontSize} />
                <GlitchText text={"what did you use to do and what places did you use to visit?"} color="#ff00ff" fontSize={fontSize} />

            </div>
        </div>

    );
}

export default Page12;


