
import React from 'react';
import './App.css';
import SpaceBackground from './SpaceBackground';
import GlitchText from './GlitchText';
import timeAtHome from './img/time_at_home.webp';

function Page10() {
    let fontSize = "2vh";
    return (
        <div>
            <SpaceBackground />
            <div className="glitch">
                <GlitchText text={"Durante a pandemia, vocês viajavam"} fontSize={fontSize} color="#ff6ec7" />
                <GlitchText text={"para se ver, e o Paulo passava"} fontSize={fontSize} color="#ff6ec7" />
                <GlitchText text={"meses na sua casa."} fontSize={fontSize} color="#ff6ec7" />


                <br />
                <img src={timeAtHome} style={{ width: 'auto', height: '30vh' }} alt="" />
                <br />
                <br />
                <br />
                <GlitchText text={"<watchers> <question>"} color="#f88379" fontSize={fontSize} />
                <GlitchText text={"what did you use to do when you got together?"} color="#ff00ff" fontSize={fontSize} />

            </div>
        </div>

    );
}

export default Page10;


